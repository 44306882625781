import React, { useState } from "react";
import Zmage from "react-zmage";
import Fade from "react-reveal";

let id = 0;

function getPhotos(photos, folder, count = 4) {
  return photos.map((photo, index) => {
    let projectImage = "images/portfolio/" + folder + "/" + photo;

    if (index > count - 1) {
      return null;
    }

    return (
      <div key={id++} className={`columns portfolio-item p20`}>
        <div className="item-wrap">
          <Zmage alt={photo} zoom={1} src={projectImage} />
        </div>
      </div>
    );
  });
}

const odaPhotos = [
  "1.webp",
  "2.webp",
  "3.jpeg",
  "4.jpeg",
  "5.webp",
  "6.jpeg",
  "7.jpg",
];

const placesPhotos = [
  "1.webp",
  "2.webp",
  "3.webp",
  "4.webp",
  "5.webp",
  "6.webp",
  "7.webp",
  "8.webp",
  "9.webp",
  "10.webp",
  "11.webp",
  "12.webp",
  "13.webp",
  "14.webp",
  "15.webp",
  "16.webp",
  "17.webp",
  "18.webp",
  "19.webp",
  "20.webp",
  "21.webp",
  "22.webp",
  "23.webp",
  "24.webp",
  "25.webp",
];

const flowerPhotos = [
  "1.webp",
  "2.webp",
  "3.webp",
  "4.webp",
  "5.webp",
  "6.webp",
  "7.webp",
  "8.webp",
  "9.webp",
  "10.webp",
  "11.webp",
  "12.webp",
  "13.webp",
  "14.webp",
  "15.webp",
  "16.webp",
];

const Portfolio = (props) => {
  const [room, setRoom] = useState(getPhotos(odaPhotos, "room"));
  const [places, setPlaces] = useState(getPhotos(placesPhotos, "place"));
  const [flowers, setFlowers] = useState(getPhotos(flowerPhotos, "flower"));

  if (!props.data) return null;

  function loadmorePhoto(type) {
    if (type === "room") {
      let odafull = getPhotos(odaPhotos, "room", odaPhotos.length);
      setRoom(odafull);
    } else if (type === "place") {
      let placefull = getPhotos(placesPhotos, "place", placesPhotos.length);
      setPlaces(placefull);
    } else if (type === "flower") {
      let flowerfull = getPhotos(flowerPhotos, "flower", flowerPhotos.length);
      setFlowers(flowerfull);
    }
    // delete that button
    document.querySelector(`.${type}MoreBtn`).style.display = "none";
  }

  return (
    <section id="portfolio">
      <Fade left duration={1000} distance="40px">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1>Projelerimizden bazı görseller</h1>

            <p className="flex-f">Oda Tekstili</p>
            <div
              id="portfolio-wrapper1"
              className="bgrid-quarters s-bgrid-thirds cf room"
            >
              {room}
              <span
                className="px-20 flex-f jc-fe cursor-pointer roomMoreBtn"
                onClick={() => {
                  loadmorePhoto("room");
                }}
              >
                Daha Fazla Yükle
              </span>
            </div>
            <p className="flex-f">İç & Dış Mekan Minder ve Kılıf</p>
            <div
              id="portfolio-wrapper2"
              className="bgrid-quarters s-bgrid-thirds cf"
            >
              {places}
              <span
                className="px-20 flex-f jc-fe cursor-pointer placeMoreBtn"
                onClick={() => {
                  loadmorePhoto("place");
                }}
              >
                Daha Fazla Yükle
              </span>
            </div>
            <p className="flex-f">Kuru & Yapay Çicek ve Ağaç</p>
            <div
              id="portfolio-wrapper3"
              className="bgrid-quarters s-bgrid-thirds cf"
            >
              {flowers}
              <span
                className="px-20 flex-f jc-fe cursor-pointer flowerMoreBtn"
                onClick={() => {
                  loadmorePhoto("flower");
                }}
              >
                Daha Fazla Yükle
              </span>
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default Portfolio;
