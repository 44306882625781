import React, { Component } from "react";
import Slide from "react-reveal";

class Resume extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    if (!this.props.data) return null;

    const skillmessage = this.props.data.skillmessage;

    return (
      <section id="resume">
        <Slide left duration={1300}>
          <div className="row education">
            <div className="three columns header-col">
              <h1>
                <span>Tasarım</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">
                  <div key="tasarim">
                    <h3>Benzersiz Tasarımlar ve Uygulamalar</h3>
                    <p className="info">
                      GOLD MARJE olarak, müşterilerimize sunduğumuz kumaş,
                      perde, kuru çiçek aranjmanları, vazo ve aksesuar tasarım
                      ve uygulama işleri için, her müşterinin farklı
                      beklentilerini karşılayacak özgün tasarımlar
                      oluşturuyoruz.
                      <br />
                    </p>
                    <p>
                      Örneğin, perde tasarımlarında, modern, minimalist ya da
                      klasik tarzda farklı seçenekler içinden beklentinizi
                      öğrenip, kumaş tasarımlarında farklı renk ve desenlerde
                      geniş bir yelpaze sunuyoruz. Kuru çiçek aranjmanları, vazo
                      ve aksesuar tasarımlarında mekan bütünlüğüne uygun
                      çalışmalar gerçekleştiriyoruz.
                    </p>
                    <p>
                      Ayrıca, müşterilerimizin özel talepleri doğrultusunda özel
                      tasarım projeleri de üstleniyoruz.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>Uygulama</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <div key="Uygulama">
                <h3>Profesyonel Uygulama</h3>
                <p className="info">
                  Tasarım ekibi, sizin için en uygun ve benzersiz tasarımı
                  oluştururken, uygulama ekibimizle ise tasarımların hayata
                  geçirilmesi için özenli bir çalışma yürütüyoruz. Uygulama
                  işleri, en yüksek kaliteli malzemeler kullanılarak profesyonel
                  bir şekilde gerçekleştiriyor ve işletmenizin işleyişine mümkün
                  olduğunca az müdahale ediyoruz.
                </p>
              </div>
            </div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className="row skill">
            <div className="three columns header-col">
              <h1>
                <span>Danısmanlık</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <div key="Uygulama">
                <h3>Proje Odaklı Danışmanlık</h3>
                <p className="info">
                  Deneyimli ekibimiz ve çözüm ortaklarımızla; işletmenizin
                  ihtiyaçlarına özgü ve potansiyelinizi artıran kapsamlı bir
                  analiz ve öneri sunuyoruz.
                </p>
              </div>
            </div>
          </div>
        </Slide>
      </section>
    );
  }
}

export default Resume;
