import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactName: "",
      firmName: "",
      contactEmail: "",
      contactSubject: "",
      contactMessage: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  // call checkFields everytime state changes
  componentDidUpdate() {
    this.checkFields();
  }

  checkFields() {
    const {
      contactName,
      firmName,
      contactEmail,
      contactSubject,
      contactMessage,
    } = this.state;
    console.log(
      contactName,
      firmName,
      contactEmail,
      contactSubject,
      contactMessage
    );
    let validEmail = false;
    let validName = false;
    let validFirmName = false;
    let validSubject = false;
    let validMessage = false;

    // check email is valid include @ and . after @

    if (contactEmail.includes("@") && contactEmail.includes(".")) {
      validEmail = true;
    }

    // check name is valid
    if (contactName.length > 3) {
      validName = true;
    }

    // check firmName is valid
    if (firmName.length > 3) {
      validFirmName = true;
    }

    // check subject is valid
    if (contactSubject.length > 3) {
      validSubject = true;
    }

    // check message is valid
    if (contactMessage.length > 3) {
      validMessage = true;
    }

    // if all fields are valid, enable submit button
    if (
      validEmail &&
      validName &&
      validFirmName &&
      validSubject &&
      validMessage
    ) {
      // enable submit button
      document.querySelector(".submit").classList.remove("disabledBtn");
      document.querySelector(".submit").disabled = false;
    } else {
      // disable submit button
      document.querySelector(".submit").classList.add("disabledBtn");
      document.querySelector(".submit").disabled = true;
    }
  }

  render() {
    if (!this.props.data) return null;

    return (
      <section id="contact">
        <Fade bottom duration={1000}>
          <div className="row section-head">
            <div className="two columns header-col">
              <h1>
                <span>ILETISIM</span>
              </h1>
            </div>

            <div className="ten columns">
              <p className="lead">
                Sizinle iletisime geçmemiz için formu doldurabilir ve ya
                iletisim bilgilerimizden bize ulasabilirisiniz.
              </p>
            </div>
          </div>
        </Fade>

        <div className="row">
          <Slide left duration={1000}>
            <div className="twelve columns">
              <form id="contactForm" name="contactForm">
                <fieldset>
                  <div>
                    <label htmlFor="contactName">
                      Ad-soyad <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactName"
                      name="contactName"
                      placeholder="Ad Soyad"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="contactName">
                      Firma Ünvanı <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="firmName"
                      name="firmName"
                      placeholder="Firma Ünvanı"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactEmail">
                      E-posta <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactEmail"
                      name="contactEmail"
                      placeholder="E-posta"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactSubject">
                      Konu <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactSubject"
                      name="contactSubject"
                      placeholder="Konu"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactMessage">
                      Mesaj <span className="required">*</span>
                    </label>
                    <textarea
                      cols="50"
                      rows="1"
                      id="contactMessage"
                      name="contactMessage"
                      placeholder="Mesaj"
                      onChange={this.handleChange}
                    ></textarea>
                  </div>

                  <div>
                    <button
                      disabled
                      className="submit disabledBtn"
                      onClick={this.handleSubmit}
                    >
                      Gönder
                    </button>
                    <span id="image-loader">
                      <img alt="" src="images/loader.gif" />
                    </span>
                  </div>
                </fieldset>
              </form>

              <div id="message-warning"> Error boy</div>
              <div id="message-success">
                <i className="fa fa-check"></i>Mesajınız başarıyla gönderildi!
                <br />
              </div>
            </div>
          </Slide>
        </div>
      </section>
    );
  }
}

export default Contact;
