import React, { Component } from "react";
import Fade from "react-reveal";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const profilepic = "logo.png";
    const bio = this.props.data.bio;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const email = this.props.data.email;
    const resumeDownload = this.props.data.resumedownload;

    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row">
            <div className="three columns">
              <img className="profile-pic" src={profilepic} alt="Logo" />
            </div>
            <div className="nine columns main-col">
              <h2>Hakkımızda</h2>

              <p>
                GOLD MARJE, 2002 yılından beri sektörde faaliyet gösteren bir
                firma olup, müşterilerine yaratıcı, özgün ve kaliteli tasarımlar
                sunma hedefiyle kurulmuştur.
              </p>
              <p>
                Misyonumuz, GOLD MARJE olarak, müşteri ihtiyaçlarına odaklanarak
                mekana özel çözümler sunmaktadır.
              </p>
              <p>
                Müşterilerimizin beklentilerini anlamak ve onlara özel
                tasarımlar oluşturmak için çözüm odaklı bir bakış açısını
                benimsemekteyiz.
              </p>
              <p>
                GOLD MARJE, tasarım sürecinde estetik, işlevsellik, kaliteli
                işçilik ve kullanılabilirlik gibi faktörleri bir araya getirerek
                mekanlara özgü ve etkileyici tasarımlar ortaya çıkarmayı
                amaçlamaktadır.
              </p>
              <p>
                Müşterilerimizin farklı zevk ve tercihlerini dikkate alarak,
                onlara kendilerini ifade edebilecekleri benzersiz tasarımlar
                sunmayı hedefliyoruz.
              </p>
              
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
